<!-- LOAODER -->
<div
  *ngIf="globalService.loading"
  class="fixed w-full h-full flex bg-black bg-opacity-50 z-50"
>
  <mat-spinner
    class="self-center justify-self-center mx-auto"
    color="warn"
  ></mat-spinner>
</div>

<div class="h-full bg-gray-200">
  <!-- HEADER -->
  <header class="bg-primary text-white sticky top-0 z-20">
    <div class="container mx-auto p-2">
      <a class="m-0 flex items-center gap-2 opacity-90 hover:opacity-100" routerLink="/">
        <img
          alt="Logo Guardia Civil"
          src="/logo_guardia_civil.png"
          style="max-height: 48px"
        />
        <span class="text-xl">E-Denuncia</span>
      </a>
    </div>
  </header>

  <!-- CONTENT -->
  <router-outlet/>

  <!-- FOOTER -->
  <!-- <footer class="bg-green-900 text-white py-2 px-3 sticky bottom-0">
     <div class="container mx-auto text-start">
       <small>&copy; 2024 DLTCode. Todos los derechos reservados.</small>
     </div>
   </footer>-->
</div>
