<form (ngSubmit)="onSubmit()" [formGroup]="myForm">
  <div class="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-4 py-6 z-20">
    <ng-container *ngFor="let control of myForm.controls | keyvalue">
      <mat-form-field
        [color]="'primary'"
        [floatLabel]="'auto'"
        [subscriptSizing]="'dynamic'"
      >
        <mat-label>{{ convertSnakeToNormal(control.key) }}</mat-label>
        <input [formControlName]="control.key" matInput>
      </mat-form-field>
    </ng-container>


  </div>
  <div class="w-full bg-white sticky p-3 bottom-0 z-50 border-t">
    <div class="container mx-auto  flex justify-between">
      <button mat-stroked-button>Cancelar</button>
      <button color="accent" mat-raised-button>
        <mat-icon>save</mat-icon>
        GUARDAR DENUNCIA
      </button>
    </div>
  </div>
</form>


