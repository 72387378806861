import {Injectable} from '@angular/core';
import {Router} from "@angular/router";

interface UserData {
  [key: string]: string;
}

export interface MessageInterface {
  speaker: 'user' | 'assistant',
  text: string
}

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  public actualDate: any;
  public loading: boolean = false;
  public conversation: MessageInterface[] = [];
  private _parsedUserData: UserData = {}

  constructor(private router: Router) {
    this.actualDate = Date.now();
  }


  private _rawData: any = ""

  get rawData() {
    return this._rawData;
  }

  set rawData(data: any) {
    this._rawData = data;
    this.parseStringResponse(data);
    this.router.navigate(['/form']);
  }

  get parsed() {
    return this._parsedUserData;
  }

  public toggleLoading(loadingState?: boolean) {
    this.loading = typeof loadingState === 'boolean' ? loadingState : !this.loading;
  }

  public parseStringResponse(inputData?: string): void {
    const input: string = typeof inputData === 'string' ? inputData : this._rawData;

    const lines = input.split('\n').filter((line: any) => line.trim().length > 0 && !line.startsWith('['));
    const result: UserData = {};

    lines.forEach((line: string) => {
      const [key, value] = line.split(':').map(part => part.trim());
      result[key] = value.replace(/"/g, '');
    });

    this._parsedUserData = JSON.parse(JSON.stringify(result));
  }
}
