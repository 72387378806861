<ng-container *ngIf="showAvatar">
  <div class="fixed right-0 top-0 p-4 flex items-center gap-3 z-50 text-white">
    <button (click)="refreshAvatar()" mat-icon-button matTooltip="Recargar Chat">
      <mat-icon>restart_alt</mat-icon>
    </button>
    <button (click)="stopAvatar();cleanSession()" class="border-gray-800" color="warn" mat-icon-button
            matTooltip="Cerrar chat">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</ng-container>

<div class="w-full flex-1 flex h-full overflow-hidden  bg-gray-300">
  <app-messages
    *ngIf="showAvatar"
    class="z-50 top-16 bottom-4 left-4 fixed opacity-80 hover:opacity-100"
  ></app-messages>


  <div *ngIf="showAvatar" class="z-50 bottom-4 right-4 fixed opacity-90 hover:opacity-100 flex gap-4">
    <button (click)="stopSpeaking()" color="accent" mat-flat-button matTooltip="Terminar respuesta">
      <mat-icon style="margin:0">skip_next</mat-icon>
    </button>
    <button (click)="getData()" color="warn" mat-raised-button>
      <mat-icon>save</mat-icon>
      SIGUIENTE
    </button>
  </div>

  <!-- BOTON INICIO -->
  <button
    (click)="startAvatar()"
    *ngIf="!showAvatar"
    [disabled]="globalService.loading"
    class="self-center justify-self-center mx-auto"
    color="primary"
    mat-flat-button
  >
    <mat-icon>play_circle</mat-icon>
    EMPEZAR CONVERSACIÓN
  </button>

  <!-- AVATAR -->
  <video
    [class.hidden]="!showAvatar"
    class="w-full h-full bg-gray-600"
    data-persona-video
    id="persona-video"
  ></video>


</div>


