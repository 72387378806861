/*
 * Copyright The OpenTelemetry Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import { ValueType } from '@opentelemetry/api-metrics';
import { hrTimeToNanoseconds } from '@opentelemetry/core';
import { AggregationTemporality, DataPointType } from '@opentelemetry/sdk-metrics';
import { toAttributes } from '../common/internal';
export function toResourceMetrics(resourceMetrics) {
    return {
        resource: {
            attributes: toAttributes(resourceMetrics.resource.attributes),
            droppedAttributesCount: 0
        },
        schemaUrl: undefined,
        scopeMetrics: toScopeMetrics(resourceMetrics.scopeMetrics)
    };
}
export function toScopeMetrics(scopeMetrics) {
    return Array.from(scopeMetrics.map(function (metrics) {
        var scopeMetrics = {
            scope: {
                name: metrics.scope.name,
                version: metrics.scope.version,
            },
            metrics: metrics.metrics.map(function (metricData) { return toMetric(metricData); }),
            schemaUrl: metrics.scope.schemaUrl
        };
        return scopeMetrics;
    }));
}
export function toMetric(metricData) {
    var out = {
        name: metricData.descriptor.name,
        description: metricData.descriptor.description,
        unit: metricData.descriptor.unit,
    };
    var aggregationTemporality = toAggregationTemporality(metricData.aggregationTemporality);
    if (metricData.dataPointType === DataPointType.SUM) {
        out.sum = {
            aggregationTemporality: aggregationTemporality,
            isMonotonic: metricData.isMonotonic,
            dataPoints: toSingularDataPoints(metricData)
        };
    }
    else if (metricData.dataPointType === DataPointType.GAUGE) {
        // Instrument is a gauge.
        out.gauge = {
            dataPoints: toSingularDataPoints(metricData)
        };
    }
    else if (metricData.dataPointType === DataPointType.HISTOGRAM) {
        out.histogram = {
            aggregationTemporality: aggregationTemporality,
            dataPoints: toHistogramDataPoints(metricData)
        };
    }
    return out;
}
function toSingularDataPoint(dataPoint, valueType) {
    var out = {
        attributes: toAttributes(dataPoint.attributes),
        startTimeUnixNano: hrTimeToNanoseconds(dataPoint.startTime),
        timeUnixNano: hrTimeToNanoseconds(dataPoint.endTime),
    };
    if (valueType === ValueType.INT) {
        out.asInt = dataPoint.value;
    }
    else if (valueType === ValueType.DOUBLE) {
        out.asDouble = dataPoint.value;
    }
    return out;
}
function toSingularDataPoints(metricData) {
    return metricData.dataPoints.map(function (dataPoint) {
        return toSingularDataPoint(dataPoint, metricData.descriptor.valueType);
    });
}
function toHistogramDataPoints(metricData) {
    return metricData.dataPoints.map(function (dataPoint) {
        var histogram = dataPoint.value;
        return {
            attributes: toAttributes(dataPoint.attributes),
            bucketCounts: histogram.buckets.counts,
            explicitBounds: histogram.buckets.boundaries,
            count: histogram.count,
            sum: histogram.sum,
            min: histogram.min,
            max: histogram.max,
            startTimeUnixNano: hrTimeToNanoseconds(dataPoint.startTime),
            timeUnixNano: hrTimeToNanoseconds(dataPoint.endTime),
        };
    });
}
function toAggregationTemporality(temporality) {
    if (temporality === AggregationTemporality.DELTA) {
        return 1 /* AGGREGATION_TEMPORALITY_DELTA */;
    }
    if (temporality === AggregationTemporality.CUMULATIVE) {
        return 2 /* AGGREGATION_TEMPORALITY_CUMULATIVE */;
    }
    return 0 /* AGGREGATION_TEMPORALITY_UNSPECIFIED */;
}
