import {Component, OnInit} from '@angular/core';
import {GlobalService} from "../../services/global.service";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {KeyValuePipe, NgForOf} from "@angular/common";
import {MatFormField, MatInput, MatLabel} from "@angular/material/input";
import {MatButton} from "@angular/material/button";
import {MatIcon} from "@angular/material/icon";

@Component({
  selector: 'app-form',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    KeyValuePipe,
    NgForOf,
    MatInput,
    MatLabel,
    MatFormField,
    MatButton,
    MatIcon
  ],
  templateUrl: './form.component.html',
  styleUrl: './form.component.scss'
})
export class FormComponent implements OnInit {
  myForm!: FormGroup;

  constructor(
    private service: GlobalService,
    private fb: FormBuilder
  ) {
    console.log('FORM COM', this.service.parsed);
  }

  ngOnInit() {
    this.myForm = this.fb.group(this.getFormData());
  }

  getFormData() {
    let formData: any = {
      apellido_1: [''],
      apellido_2: [''],
      ccaa: [''],
      ccaa_lugar_del_hecho: [''],
      codigo_postal: [''],
      correo_electronico: [''],
      direccion: [''],
      direccion_lugar_del_hecho: [],
      dni: [''],
      fecha_nacimiento: [''],
      hijo_de: [''],
      hora_de_los_hechos_desde_el: [null],
      hora_de_los_hechos_hasta_el: [''],
      lugar_del_hecho: [''],
      manifestacion_de_como_sucedieron_los_hechos: [],
      municipio: [],
      municipio_lugar_del_hecho: [''],
      municipio_residencia: [''],
      nacionalidad: [''],
      naturaleza_del_lugar: [''],
      nie: [''],
      nombre: [''],
      num_documento: [''],
      numero: [''],
      numero_lugar_del_hecho: [''],
      otro_telefono: [''],
      pais: [''],
      pais_nacimiento: [''],
      pasaporte: [''],
      permiso_de_conducir: [''],
      piso: [''],
      provincia: [''],
      provincia_lugar_del_hecho: [''],
      provincia_residencia: [''],
      resto: [''],
      resto_lugar_del_hecho: [''],
      sexo: [''],
      telefono_fijo: [''],
      telefono_movil: [''],
      tipo_de_via: [''],
      tipo_de_via_lugar: [''],
      tipo_documento: [''],
      y_de: ['']
    }


    return {...formData, ...this.service.parsed};
  }

  onSubmit(): void {
    console.log(this.myForm.value);
  }

  convertSnakeToNormal(text: string): string {
    return text
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
      .replace(/\b\w/g, char => char.toUpperCase());
  }
}
