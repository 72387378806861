import {Component, HostListener, OnInit} from '@angular/core';
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {DatePipe, NgIf} from "@angular/common";
import {MatButton, MatIconButton} from "@angular/material/button";
import {MatIcon} from "@angular/material/icon";
import {GlobalService} from "../../services/global.service";
import {Scene} from "@soulmachines/smwebsdk/lib";
import {Persona} from "@soulmachines/smwebsdk";
import {MessagesComponent} from "./messages/messages.component";
import {MatTooltip} from "@angular/material/tooltip";

let scene: Scene | any = undefined;
let persona: Persona | any = undefined;

const SESSION_STORE = 'sm-session-id'
const VAR_RESPONSE_KEYS = '[RESPUESTA_SECRETA]';


@Component({
  selector: 'app-chat',
  standalone: true,
  imports: [
    MatProgressSpinner,
    NgIf,
    MatButton,
    MatIcon,
    DatePipe,
    MessagesComponent,
    MatTooltip,
    MatIconButton
  ],
  templateUrl: './chat.component.html',
})
export class ChatComponent implements OnInit {
  videoEl: any;
  apiKey: string = "eyJzb3VsSWQiOiJkZG5hLWRsdGNvZGU3YjQzLS1leHBsb3JlciIsImF1dGhTZXJ2ZXIiOiJodHRwczovL2RoLnNvdWxtYWNoaW5lcy5jbG91ZC9hcGkvand0IiwiYXV0aFRva2VuIjoiYXBpa2V5X3YxX2ExNThjM2IyLWFiYTctNDRjNC1hMDZjLWFjYTEzOTQxNjAwMiJ9";
  showAvatar: boolean = false
  sceneResizeTimeout: any;

  private isMute: boolean = false;


  constructor(
    public globalService: GlobalService
  ) {
  }

  ngOnInit() {
    this._createScene();
    this.startAvatar()
  }

  public async getData() {
    this.globalService.loading = true;

    this.stopSpeaking();
    this.toggleMute();

    let prompt = "Dame listado con todos los DATOS elemntos recopilados hasta ahora.";
    prompt += "Solo quiero ver la lista al estilo 'clave : valor', sin más explicación.";
    prompt += 'En caso de venir vacío el valor, pon una cadena vacía; clave: ""'
    prompt += `El mensaje debería empezar con el textual ${VAR_RESPONSE_KEYS}`;
    prompt += "Las claves deberían en snake case";


    this.sendUserText(prompt);

    this.toggleMute();

  }

  public sendUserText(text: string): Promise<any> | null {
    if (scene && scene.isConnected() && persona && text) {
      return persona.conversationSend(text, {}, {});
    }

    return null;
  }

  async stopSpeaking() {
    if (scene && scene.isConnected() && persona) {
      return persona.stopSpeaking();
    }

    return null;
  }

  toggleMute() {
    if (scene && scene.isConnected()) {
      if (this.isMute) {
        return scene.startRecognize();
      } else {
        return scene.stopRecognize();
      }
      this.isMute = !this.isMute;
    }

    return null
  }

  public stopAvatar() {
    this.globalService.loading = true;
    scene.disconnect();
    this.showAvatar = this.globalService.loading = false;

  }

  public refreshAvatar() {
    this.globalService.loading = true;
    this.stopAvatar();
    this.startAvatar();
  }


  public startAvatar() {
    this.globalService.loading = true;
    this.showAvatar = true;

    // build
    const connectionConfig: any = {
      retryOptions: {
        maxRetries: 3,
        delayMs: 400
      }
    }

    const existingSessionId = sessionStorage.getItem(SESSION_STORE);
    if (existingSessionId) {
      connectionConfig.sessionId = existingSessionId;
    }

    scene.connect(connectionConfig)
      .then((sessionId: any) => {
        // set video bonds
        this._setBoundsToScene();
        this._onConnectionSuccess(sessionId)
        sessionStorage.setItem(SESSION_STORE, sessionId);
        persona = new Persona(scene, scene.currentPersonaId);
        persona.onSpeechMarkerEvent.addListener(this.onSpeechMarker.bind(this));
      })
      .catch((error: any) => {
        console.error(error);
        this.showAvatar = false;
        this.cleanSession()
      })
      .finally(() => this.globalService.loading = false);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    clearTimeout(this.sceneResizeTimeout);
    this.sceneResizeTimeout = setTimeout(() => this._setBoundsToScene(), 500)
  }

  public cleanSession() {
    sessionStorage.removeItem(SESSION_STORE)
  }

  private _onConnectionSuccess(sessionId: any) {
    this.globalService.loading = true;

    // start the video playing
    scene.startVideo()
      .catch((error: any) => console.log('could not start video: ', error))
      .finally(() => this.globalService.loading = false);
  }

  private onSpeechMarker(persona: Persona, message: any): void {
    // Add your logic to handle speech markers
    console.log('onspeech marker', persona, message)
  }


  private _setBoundsToScene() {
    scene?.sendVideoBounds(window.innerWidth, window.innerHeight - 64);
  }

  private _createScene() {
    this.videoEl = document.getElementById('persona-video');
    // @ts-ignore
    scene = new Scene({
      apiKey: this.apiKey,
      enablePersistence: true,
      videoElement: this.videoEl,
      requestedMediaDevices: {microphone: true, camera: true},
      requiredMediaDevices: {},
    })

    scene.onDisconnectedEvent.addListener(() => {
      console.log('disconnect');
      this.globalService.loading = this.showAvatar = false;
    });

    scene.onMessage = (message: any) => {
      if (message.kind === 'event') {
        const conversationResult = message.body;
        let transcription: any = undefined

        if (conversationResult?.input || conversationResult?.output) {
          const responseText: string = conversationResult?.input?.text ?? conversationResult?.output?.text ?? '...';
          // if response  contain VAR_RESPONSE_KEYS
          if (!responseText.includes(VAR_RESPONSE_KEYS)) {
            transcription = {
              speaker: 'asistant',
              text: responseText
            }
          } else {
            this.globalService.rawData = responseText;
            this.stopAvatar();
          }
        }


        const result: any = message?.body?.results?.[0];

        if (result?.final) {
          const transcript: any = result?.alternatives?.[0]?.transcript;
          if (transcript?.length) {
            transcription = {
              speaker: 'user',
              text: transcript ?? '...'
            }
          }
        }

        if (transcription?.speaker) {
          this.globalService.conversation.push(transcription)
        }


      } else {
        // console.log('NOT EVENT', message);
      }
    }
  }

}
