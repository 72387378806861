<div
  *ngIf="globalService.conversation?.length"
  @fadeIn
  class="h-full max-w-md w-md rounded message-chat-container"
>
  <!-- Header -->
  <!--<header class="bg-blue-600 text-white py-4 px-2 rounded-t-lg">
    <strong>conversacio</strong>
  </header>
-->


  <!-- Chat messages -->
  <div class="flex-1 overflow-y-auto h-full">
    <div class="flex flex-col gap-3 p-3 h-full justify-end">
      <div
        *ngFor="let message of globalService.conversation"
        [ngClass]="{
          'self-end bg-blue-600 text-white ml-4 rounded-br-none': message.speaker == 'user',
          'bg-gray-200 text-gray-700 mr-4 rounded-bl-none': message.speaker != 'user'
        }"
        class="p-2 rounded-md message-container shadow-sm "
      >
        <div class="text-sm">{{ message.text }}</div>
      </div>

    </div>
  </div>

  <!-- Input area -->
  <!--<div class="bg-gray-200 p-4 rounded-b-lg">
    <form class="flex space-x-4">
      <input type="text" placeholder="Type a message..." class="flex-1 px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600">
      <button type="submit" class="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-600">Send</button>
    </form>
  </div>-->
</div>
