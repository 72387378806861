import {Component, inject} from '@angular/core';
import {GlobalService} from "../../../services/global.service";
import {NgClass, NgForOf, NgIf} from "@angular/common";
import {animate, state, style, transition, trigger} from "@angular/animations";

@Component({
  selector: 'app-messages',
  standalone: true,
  imports: [
    NgClass,
    NgForOf,
    NgIf
  ],
  templateUrl: './messages.component.html',
  styles: `
    .message-chat-container {
      background: rgba(255, 255, 255, 0.5);
      background: linear-gradient(68deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.10) 35%, rgba(255, 255, 255, 0) 100%);
      min-width: 275px;
      max-width: 20vw;
      width: 100%;

      .message-container {
        line-height: 1 !important;
      }
    }
  `,
  animations: [
    trigger('fadeIn', [
      state('void', style({
        opacity: 0
      })),
      transition(':enter', [
        animate('0.75s ease-in')
      ])
    ])
  ]
})
export class MessagesComponent {
  public globalService: GlobalService = inject(GlobalService);
}
