<section class="bg-white p-8 max-w-md mx-auto mt-6 rounded shadow-md hover:shadow-lg">
  <h2 class="text-2xl font-semibold mb-6 text-center">Denuncias en Línea</h2>
  <p class="mb-6">Bienvenido al portal de denuncias en línea de la Guardia Civil. Aquí puede iniciar el proceso para
    presentar una denuncia.</p>
  <button class="mt-4 mb-2 w-full" color="accent" mat-flat-button routerLink="/chat">
    Iniciar Denuncia
  </button>
</section>



